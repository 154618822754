import React, {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import {format} from 'date-fns'
import {DateRangePicker} from 'react-date-range'
import moment from 'moment'

type SelectionRange = {
  startDate: string
  endDate: string
}

type AppDateRangePickerPropsType = {
  isVisible: boolean
  onCloseRangePicker: () => void
  onPickRange: (range: SelectionRange) => void
}

const AppDateRangePicker: FC<AppDateRangePickerPropsType> = ({
  isVisible,
  onPickRange,
  onCloseRangePicker,
}) => {
  const [selectedRange, setSelectedRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ])

  return (
    <Modal backdrop='static' centered show={isVisible} className='w-100 rounded'>
      <DateRangePicker
        date={new Date()}
        maxDate={new Date()}
        ranges={selectedRange}
        rangeColors={['#0d6efd', '#3ecf8e', '#fed14c']}
        classNames={{
          selected: 'bg-info',
          daySelected: 'bg-info',
          calendarWrapper: 'overflow-x-scroll w-sm-100',
        }}
        onChange={(ev: any) => {
          console.log('ev', ev)
          setSelectedRange([ev.selection])
        }}
      />
      <Modal.Footer className='p-0'>
        <button
          className='btn btn-sm btn-secondary'
          onClick={() => {
            setSelectedRange([
              {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
              },
            ])
            onCloseRangePicker()
          }}
        >
          Close
        </button>
        <button
          className='btn btn-sm btn-primary'
          onClick={() => {
            onPickRange({
              startDate: moment(selectedRange[0].startDate)
                .utc(true)
                .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
              endDate: moment(selectedRange[0].endDate)
                .utc(true)
                .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            })
            onCloseRangePicker()
          }}
        >
          Apply
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default AppDateRangePicker
