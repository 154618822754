/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect, useState} from 'react'
import {Routes, Route, BrowserRouter, Navigate, HashRouter} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import { useAppDispatch, useAppSelector } from '../../redux/storeHooks'
import { setLoggingData } from '../../redux/slices/authSlice'
import { accessTokenKeyName } from '../../utils/constants'
import { ConfirmEmailAndResetPassword } from '../modules/auth/components/ConfirmEmailAndResetPassword'
import { getLoggedInUserDetailAsync } from '../../redux/actions/authActions'
import Loader from '../../utils/Loader'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  // const {currentUser} = useAuth()
  const {isLoggedIn} = useAppSelector((state) => state.auth)
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const token = localStorage.getItem(accessTokenKeyName)
    if (token) {
      setLoading(true)
      dispatch(getLoggedInUserDetailAsync())
      .then((res)=>{
        console.log('user data res', res.payload);
        if(res.payload?.status){
          dispatch(setLoggingData({isLoggedIn:true, userData:{firstName: res.payload?.data?.first_name ,lastName: res.payload?.data?.last_name ,email: res.payload?.data?.email , userId: res.payload?.data?.id } }))
        }
        setLoading(false)
      })
      .catch(()=>{
        setLoading(false)
      })
    }
    return () => {
      dispatch(setLoggingData({isLoggedIn: false, userData: null}))
    }
    
  }, [])
  
  if(loading) return <Loader/>

  return (
    <HashRouter basename='/' >
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {isLoggedIn ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='reset-password-confirm/:encodedUser/:resetToken' element={<ConfirmEmailAndResetPassword />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </HashRouter>
  )
}

export {AppRoutes}
