import React, {FC, useRef} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import SelectSeasonMonths from './SelectSeasonMonths'
import {CatalogType} from '../../../_interfaces/catalog.interface'
import AppSwitchInput from '../../components/AppSwitchInput'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import clsx from 'clsx'

type ManageSeasonsPropsType = {
  row: CatalogType
  rowIndex: number
  catalogData: CatalogType[]
  setCatalogData: React.Dispatch<React.SetStateAction<CatalogType[]>>
}

const ManageSeasons: FC<ManageSeasonsPropsType> = ({
  row,
  rowIndex,
  catalogData,
  setCatalogData,
}) => {

    const onSwitchOn = () => {
        return true
    }

    const menuBtnRef = useRef<HTMLButtonElement|null>(null)

  return (
    <div className='d-flex align-items-center'>
      <OverlayTrigger overlay={<Tooltip>Set seasonal month for {row.product_name} </Tooltip>} >
        <button
          ref={menuBtnRef}
          type='button'
          className={clsx('btn btn-sm btn-icon', row.seasonal_months.length ? 'btn-info px-2 w-60px' : 'btn-color-info btn-light-info px-2 w-60px')}
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
          >
            <div className='d-flex align-items-center justify-content-between w-100' >
              <i className="bi bi-info-circle ms-1"></i>
              <KTIcon iconName='arrow-down' className='fs-2 svg-icon-2 rotate-180' />
            </div>
        </button>
        </OverlayTrigger>

      <SelectSeasonMonths
        rowIndex={rowIndex}
        rowData={row}
        selectedSeasons={row.seasonal_months}
        onChangeSelectedMonths={(selectedMonths) => {
          let shadow = [...catalogData]
          let rowItem = {...row}
          rowItem.seasonal_months = selectedMonths
          shadow[rowIndex] = rowItem
          setCatalogData(shadow)
        }}
      />
    </div>
  )
}

export default ManageSeasons
