import React, {FC} from 'react'
import {Modal} from 'react-bootstrap'
import { KTSVG } from '../../../../_metronic/helpers'

type DeleteConfirmationModalPropType = {
    isOpen:boolean
    isProcessing:boolean
    labelOnProcessingTime:string
    onCancel:()=>void
    onAccept : ()=> void
}

const DeleteConfirmationModal: FC<DeleteConfirmationModalPropType> = ({isOpen,onAccept,onCancel,isProcessing,labelOnProcessingTime}) => {
  return (
    <Modal centered show={isOpen} >
      <Modal.Body className='p-0 mb-0' >
        <div className='alert alert-dismissible bg-light-danger d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-0'>
           
          <span className='svg-icon svg-icon-5tx svg-icon-danger mb-5'>
            <KTSVG path="media/icons/duotune/general/gen045.svg" className="svg-icon-danger svg-icon-2hx" />

          </span>

          <div className='text-center'>
            <h5 className='fw-bolder fs-1 mb-5'>Are you sure ?</h5>

            <div className='separator separator-dashed border-danger opacity-25 mb-5'></div>

            <div className='mb-9'>
              Want to disconnect this integration
              {/* The alert component can be used to highlight certain parts of your page for{' '}
              <strong>higher content visibility</strong>.<br />
              Please read our{' '}
              <a href='#' className='fw-bolder me-1'>
                Terms and Conditions
              </a>{' '}
              for more info. */}
            </div>
            <div className='d-flex flex-center flex-wrap'>
              <button disabled={isProcessing} onClick={onCancel} className='btn btn-sm btn-outline btn-outline-danger btn-active-danger m-2'>
                Cancel
              </button>
              <button disabled={isProcessing} onClick={onAccept} className='btn btn-sm btn-danger m-2'>
                { isProcessing ? labelOnProcessingTime : 'Yes'}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default DeleteConfirmationModal
