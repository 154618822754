import React, { FC } from 'react'
import { Platform } from '../../../../_interfaces/platform.interface'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Link } from 'react-router-dom'
type AvailablePlatformCardPropsType = {
    platform: Platform
    onClickToConnect:(itemDta:Platform)=>void
}
const AvailablePlatformCard:FC<AvailablePlatformCardPropsType> = ({platform,onClickToConnect}) => {
  return (
    <div className='col-xl-2 col-md-4 col-sm-6 col-12 rounded ' >
        <div className='card card-custom bg-hover-light border border-2 '>
        <div className='card-body'>
          <div className='text-center' >
            <img src={platform.logo_url} className='h-100px' alt={platform.name} loading='lazy' />
            <br />
            <br />
            <p className='mb-0 h1' >{platform.name}</p>
          </div>
          <button className='btn btn-sm btn-info w-100 mt-4 ' onClick={()=>onClickToConnect(platform)} >Connect</button>
        </div>
      </div>
    </div>
  )
}

export default AvailablePlatformCard