import React, {useEffect, useState} from 'react'
import {useAppDispatch, useAppSelector} from '../../../redux/storeHooks'
import {getAllLocationsAsync, getCatalogDataAsync} from '../../../redux/actions/catalogActions'
import BreadCrumbs from '../../../utils/BreadCrumbs'
import {CatalogType} from '../../../_interfaces/catalog.interface'
import DataTable, {ConditionalStyles, TableColumn} from 'react-data-table-component'
import AppDateRangePicker from '../../components/AppDateRangePicker'
import moment from 'moment'
import {Link} from 'react-router-dom'
import AppSwitchInput from '../../components/AppSwitchInput'
import {KTIcon} from '../../../_metronic/helpers'
import SelectSeasonMonths from './SelectSeasonMonths'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import CustomInfoToolTip from '../../components/CustomInfoToolTip'
import { getActivePlatformsAsync } from '../../../redux/actions/platformActions'
import ManageLeadTime from './ManageLeadTime'
import ManageSeasons from './ManageSeasons'
import Loader from '../../../utils/Loader'
import useAppToast from '../../../hooks/useAppToast'

const Catalog = () => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const { warningToast } = useAppToast()
  const [initialLoading, setInitialLoading] = useState<boolean>(true)
  const [openDateRangePicker, setOpenDateRangePicker] = useState<boolean>(false)
  const {activeIntegrations} = useAppSelector((state) => state.integration)
  const {allActiveLocations} = useAppSelector((state) => state.catalog)
  const [searchVal, setSearchVal] = useState('')



  const today1 = new Date()
  const sevenDaysAgo1 = new Date(today1)
  sevenDaysAgo1.setDate(today1.getDate() - 7)
  const [catalogFilter, setCatalogFilter] = useState({
    startDate: moment(sevenDaysAgo1.toString()).utc(true).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
    endDate: moment(new Date().toDateString()).utc(true).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
    sortBy: '',
    sortDirection: '',
    page: 1,
    page_size: 10,
    connected_platform: 'all',
    location_id:'all',
    search:''
  })
  const [catalogData, setCatalogData] = useState<CatalogType[]>([])
  const [totalRecords, setTotalRecords] = useState<number>(0)

  useEffect(() => {
    setInitialLoading(true)
    Promise.all([
      dispatch(getAllLocationsAsync()),
      dispatch(getActivePlatformsAsync())
    ]).finally(()=>{
      setInitialLoading(false)
    })
    
  }, [])
  

  useEffect(() => {
    setLoading(true)
    dispatch(getCatalogDataAsync({...catalogFilter}))
      .then((res) => {
        if (res.payload?.data?.length) {
          setCatalogData(res.payload?.data)
        }else{
          setCatalogData([])
        }
        setTotalRecords(res.payload?.total_records)
        if(res.payload?.data?.status==500){
          warningToast(res.payload?.data?.error)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        setLoading(false)
      })
  }, [catalogFilter])

  const columns: TableColumn<CatalogType>[] = [
    {
      name: '',
      cell: (row) => {
        return (
          <div>
            <OverlayTrigger overlay={<Tooltip>{row.inventories[0]?.stock_info?.message}</Tooltip>} >
            <i
              className='bi bi-info-circle-fill fs-3'
              style={{color: row.inventories[0]?.stock_info?.color ?? 'gray'}}
            ></i>
            </OverlayTrigger>
          </div>
        )
      },
      width: 'max-content',
    },
    {
      name: 'Store',
      cell: (row) => row?.store_name,
      minWidth: '140px',
    },
    {
      name: 'Product Name',
      cell: (row) => row?.product_name,
      minWidth: '140px',
    },
    // {
    //   name: 'SKU',
    //   cell: (row) => row?.total_sales,
    //   minWidth: '140px',
    // },
    {
      name: 'Vendor Name',
      cell: (row) => row?.vendor,
      minWidth: '140px',
    },
    {
      name: 'Total Sales',
      cell: (row) => row?.total_sales,
      minWidth: '140px',
    },
    // {
    //   name: 'Current Aval. Qty.',
    //   cell: (row) => row?.is_seasonal,
    //   minWidth: '180px',
    // },
    // {
    //   name: 'Predicted Qty.',
    //   cell: (row) => row?.product_id,
    //   sortable: true,
    //   sortField: 'predicted_quantity',
    //   minWidth: '180px',
    // },

    // {
    //   name: 'Location',
    //   cell: (row) => row?.vendor,
    //   minWidth: '200px',
    // },
   
    {
      name: 'Total Predicted Qty',
      minWidth: '180px',
      cell : (row, rowIndex, column, id) => row.total_predicted_quantity,
    },
    {
      name: 'Last Prediction Date',
      minWidth: '180px',
      cell:(row, rowIndex, column, id) =>row.last_predicted_date,
    },
    // {
    //   name: 'Lead Time (In days) ',
    //   minWidth: '180px',
    //   cell(row, rowIndex, column, id) {
    //     return (
    //       <ManageLeadTime row={row} />
    //     )
    //   },
    // },
    {
      name: <div className='d-flex align-items-center' >Is Seasonable</div>,
      minWidth:'160px',
      cell(row, rowIndex, column, id) {
        console.log('rowIndex', rowIndex)
        return<ManageSeasons
          rowIndex={rowIndex}
          row={row}
          catalogData={catalogData}
          setCatalogData={setCatalogData}
          
        />
      },
    },
  ]

  const clearFilter = () => {
    const today = new Date()
    const sevenDaysAgo = new Date(today)
    sevenDaysAgo.setDate(today.getDate() - 7)
    setSearchVal('')
    setCatalogFilter({
      startDate: moment(sevenDaysAgo.toString()).utc(true).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      endDate: moment(new Date().toDateString()).utc(true).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      sortBy: '',
      sortDirection: '',
      page: 1,
      page_size: 10,
      connected_platform: 'all',
      location_id:'all',
      search:''
    })
  }

  const handleSort = (sortColumn: string, sortDirection: string) => {
    setCatalogFilter((pre) => ({...pre, sortBy: sortColumn, sortDirection: sortDirection}))
  }

  if(initialLoading) return <Loader/>

  return (
    <div>
      <h1>Catalogs</h1>
      <div className='card mt-4'>
        <div className='card-header'>
          <div className='card-title'>
            <div>
              <label className='form-label fs-8 mb-0' >Select Store</label>
            <select
              value={catalogFilter.connected_platform}
              className='form-select form-select-sm border border-2 border-secondary'
              onChange={(ev) => {
                setCatalogFilter((pre) => ({...pre, connected_platform: ev.target.value}))
              }}
            >
              <option value=''>Select Store</option>
              {activeIntegrations.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.friendly_name}
                  </option>
                )
              })}
            </select>
            </div>
            <div>
            <label className='form-label fs-8 mb-0' >Select Location</label>
            <select
              value={catalogFilter.location_id}
              className='form-select form-select-sm border border-2 border-secondary ms-2'
              onChange={(ev) => {
                setCatalogFilter((pre) => ({...pre, location_id: ev.target.value}))
              }}
            >
              <option value=''>Select Location &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
              {
                  allActiveLocations.map((item)=>{
                    return <option key={item.location_id} value={item.location_id}>{item.name}</option>
                     
                  })
                }
            </select>
            </div>
            <div className='ms-4' >
              <label htmlFor=""></label>
          <input 
            type="text" 
            className='form-control form-control-sm' 
            placeholder='Search'
            value={searchVal} 
            onChange={(ev)=>setSearchVal(ev.target.value)} 
            onKeyDown={(ev)=>{
              if(ev.key==='Enter'){
                setCatalogFilter((pre)=>({...pre, search:searchVal }))
              }
            }} 
          />

            </div>
          </div>
          <div className='card-toolbar'>
              <CustomInfoToolTip msg='Get Product sales count based on date range' />&nbsp;
            <div className='d-flex'>
              <button
                className='btn btn-sm border border-2 border-secondary'
                onClick={() => setOpenDateRangePicker(true)}
              >
                 <div className='d-inline-flex' >
                  {catalogFilter.startDate && catalogFilter.endDate
                    ? `${moment(catalogFilter.startDate).format('YYYY/MM/DD')}  -  ${moment(
                        catalogFilter.endDate
                      ).format('YYYY/MM/DD')}`
                    : 'Pick a Date Range'}
                 </div>
              </button>
              <button
                className='btn btn-sm btn-warning ms-2'
                onClick={() => {
                  clearFilter()
                }}
              >
                Reset
              </button>
            </div>
            <AppDateRangePicker
              isVisible={openDateRangePicker}
              onCloseRangePicker={() => {
                setOpenDateRangePicker(false)
              }}
              onPickRange={(ev) => {
                setCatalogFilter((pre) => ({...pre, startDate: ev.startDate, endDate: ev.endDate}))
              }}
            />
          </div>
        </div>
        <div className='card-body'>
          <DataTable
            key={'catalog'}
            responsive
            columns={columns}
            data={catalogData}
            progressPending={loading}
            persistTableHead
            pagination
            paginationServer
            fixedHeader
            expandOnRowClicked
            expandableIcon={{
              expanded: <i className='bi bi-arrow-down-square-fill fs-2 text-info fw-bold w-100'></i>,
              collapsed: <OverlayTrigger overlay={<Tooltip>Check Warehouse wise product info</Tooltip>} ><i className='bi bi-arrow-right-square-fill fs-2 text-info fw-bold w-100 '></i></OverlayTrigger>  ,
            }}
            expandableRows
            expandableRowsComponent={({data}) => {
              return (
                <div>
                  <div className=' border p-2'>
                    <table className='table table-rounded  table-row-bordered border border-2 table-row-gray-300 p-4'>
                      <thead>
                        <tr className='fw-bolder fs-8 text-gray-800'>
                         <th></th>
                          <th>SKU</th>
                          <th>Current Quantity</th>
                          <th>Location</th>
                          <th>Predicted Quantity</th>
                          <th>Total Sales Qty</th>
                          <th>Lead Time (in days)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          data.inventories.map((inventory)=>{
                            return(
                                    <tr>
                                      
                                      <td><CustomInfoToolTip msg={inventory?.stock_info?.message??''}  /></td>
                                      <td>  {inventory.api_sku}
                                        </td>
                                      <td>{inventory.api_quantity}</td>
                                      
                                      <td>{inventory.location}</td>
                                      <td>{inventory.predicted_quantity}</td>
                                      <td>{inventory.total_sales_quantity}</td>
                                      <td style={{maxWidth:'140px'}} ><ManageLeadTime row={inventory} /></td>
                                    </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              )
            }}
            paginationTotalRows={totalRecords}
            onChangePage={(pageNum, totalRows) => {
              setCatalogFilter((pre) => {
                return {...pre, page: pageNum}
              })
            }}
            onChangeRowsPerPage={(rowNumbers) => {
              setCatalogFilter((pre) => {
                return {...pre, page_size: rowNumbers}
              })
            }}
            onSort={(columnData, sortDirection) => {
              console.log(sortDirection)

              handleSort(columnData.sortField as string, sortDirection)
            }}
            customStyles={{
              table: {
                style: {
                  height: '52vh',
                },
              },
              head: {
                style: {
                  fontSize: '14px',
                  fontWeight: 'bold',
                },
              },
            }}
            noDataComponent={
              <div>
                {
                  activeIntegrations.length ?
                  <div>
                      <p>No Records Found</p>
                  </div> : 
                  (
                    <div className=' mt-8 alert alert-info d-flex align-items-center p-8 mb-10'>
                      <span className='svg-icon svg-icon-2hx svg-icon-info me-3'>
                        <i className='bi bi-info-circle fs-2x text-info'></i>
                      </span>

                      <div className='d-flex flex-column'>
                        <h4 className='mb-2'>There is no active integrations</h4>
                        <span className='text-muted'>
                          For create new integrations{' '}
                          <Link className='fw-bold' to={'/integrations'}>
                            Click Here <i className='bi bi-box-arrow-up-right text-primary fs-2'></i>
                          </Link>
                          .
                        </span>
                      </div>
                    </div>
                  )
                }
              </div>
            }
          />
        </div>
      </div>
    </div>
  )
}

export default Catalog
