import React, {FC} from 'react'
import {AVAILABLE_PLATFORM_AUTH_REQUIREMENT} from '../../../../_interfaces/platform.interface'

type AuthRequirenmentInputPropsType = {
  authData: AVAILABLE_PLATFORM_AUTH_REQUIREMENT
  setFormValues: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string
    }>
  >
 fieldValue:string
}

const AuthRequirenmentInput: FC<AuthRequirenmentInputPropsType> = ({authData, fieldValue, setFormValues }) => {
  if (authData.input_type == 'text') {
    return (
      <div>
        <label className='form-label' htmlFor={authData.display_name + authData.id}>
          {authData.display_name}
        </label>
        <input
          id={authData.display_name + authData.id}
          required
          type='text'
          placeholder={authData.display_name}
          value={fieldValue}
          className='form-control form-control-sm form-control-solid'
          onChange={(ev)=>{
            setFormValues(pre=>{
                return {...pre, [authData.name]:ev.target.value }
            })
          }}
        />
      </div>
    )
  } else {
    return null
  }
}

export default AuthRequirenmentInput
