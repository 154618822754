import {createAsyncThunk} from '@reduxjs/toolkit'
import AxiosClient from '../AxiosClient'

export const loginAsync = createAsyncThunk(
  'auth/loginAsync',
  async function (payload: {email: string; password: string}, toolkit) {
    return await AxiosClient('POST', '/login/', payload, toolkit, [])
  }
)

export const registrationAsync = createAsyncThunk(
  'auth/registrationAsync',
  async function (
    payload: {
      firstName: string
      lastName: string
      email: string
      password: string
    },
    toolkit
  ) {
    return await AxiosClient('POST', '/register/', payload, toolkit, [])
  }
)

export const getLoggedInUserDetailAsync = createAsyncThunk(
  'auth/getLoggedInUserDetailAsync',
  async function (_, toolkit) {
    return await AxiosClient('GET', '/user_profile/', [], toolkit, [])
  }
)

export const requestForResetPassViaMainAsync = createAsyncThunk(
  'auth/requestForResetPassViaMainAsync',
  async function (
    payload: {
      email: string
    },
    toolkit
  ) {
    return await AxiosClient('POST', '/forgot-password/', payload, toolkit, [])
  }
)

export const verifyResetPasswordTokenAsync = createAsyncThunk(
  'auth/verifyResetPasswordTokenAsync',
  async function (
    params: {
      token: string
    },
    toolkit
  ) {
    return await AxiosClient('POST', `/validated_forgot_token/${params.token}/`, [], toolkit, [])
  }
)

export const resetPasswordAsync = createAsyncThunk(
  'auth/resetPasswordAsync',
  async function (
    payload: {
      userId: string
      payload: {
        new_password: string
        confirm_password: string
      }
    },
    toolkit
  ) {
    return await AxiosClient(
      'POST',
      `/reset-password/${payload.userId}`,
      payload.payload,
      toolkit,
      []
    )
  }
)

export const updateProfileDetailAsync = createAsyncThunk(
  'auth/updateProfileDetailAsync',
  async function (
    payload: {
      userId: string
      payload: {
        first_name: string
        last_name: string
      }
    },
    toolkit
  ) {
    return await AxiosClient('PUT', `/user_profile/${payload.userId}`, payload.payload, toolkit, [])
  }
)

export const updatePasswordAsync = createAsyncThunk(
  'auth/updatePasswordAsync',
  async function (
    payload: {
      previousPassword: string
      newPassword: string
      confirmPassword: string
    },
    toolkit
  ) {
    return await AxiosClient('POST', `/change-password/`, payload, toolkit, [])
  }
)

export const getUserGeneralSettingsAsync = createAsyncThunk(
  'auth/getUserGeneralSettingsAsync',
  async function (params: any, toolkit) {
    return await AxiosClient('GET', `/api/user_account_settings`, [], toolkit, params)
  }
)

export const setUserGeneralSettingsAsync = createAsyncThunk(
  'auth/setUserGeneralSettingsAsync',
  async function (payload: any, toolkit) {
    return await AxiosClient('POST', `/api/user_account_settings`, payload, toolkit, [])
  }
)

export const updateUserGeneralSettingsAsync = createAsyncThunk(
  'auth/updateUserGeneralSettingsAsync',
  async function (payload: any, toolkit) {
    return await AxiosClient(
      'PUT',
      `/api/user_account_settings/${payload.id}`,
      payload,
      toolkit,
      []
    )
  }
)

export const getCountryListAsync = createAsyncThunk(
  'auth/getCountryListAsync',
  async function (_, toolkit) {
    return await AxiosClient('GET', `/api/get_country`, [], toolkit, [])
  }
)

export const getTimezoneListsAsync = createAsyncThunk(
  'auth/getTimezoneListsAsync',
  async function (_, toolkit) {
    return await AxiosClient('GET', `/api/get_regional_timezone`, [], toolkit, [])
  }
)

export const getForecastSettingAsync = createAsyncThunk(
  'auth/getForecastSettingAsync',
  async function (params:any, toolkit) {
    return await AxiosClient('GET', `/api/forcasting_settings`, [], toolkit, params)
  }
)

export const setForcastSettingAsync = createAsyncThunk(
  'auth/setForcastSettingAsync',
  async function (payload:any, toolkit) {
    return await AxiosClient('POST', `/api/forcasting_settings`, payload, toolkit, [])
  }
)
