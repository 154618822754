import React, {FC, useEffect, useState} from 'react'
import {useAppDispatch, useAppSelector} from '../../../../redux/storeHooks'
import useAppToast from '../../../../hooks/useAppToast'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {
  getForecastSettingAsync,
  setForcastSettingAsync,
} from '../../../../redux/actions/authActions'
import Loader from '../../../../utils/Loader'
import AppSwitchInput from '../../../components/AppSwitchInput'
import CustomInfoToolTip from '../../../components/CustomInfoToolTip'
import { useParams } from 'react-router-dom'

const IndivisualForecastSetting: FC = () => {

  const dispatch = useAppDispatch()
  const {successToast, errorToast, warningToast} = useAppToast()
  const [showCustomPeriodDuration, setShowCustomPeriodDuration] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedStore, setSelectedStore] = useState('')
  const [customPeriodDuration, setCustomPeriodDuration] = useState<number>(0)
  
  const today = new Date()
  const params = useParams()
  let endDate = today // Always ends at the current month

  const validationSchema = Yup.object().shape({
    id: Yup.number().nullable(),
    use_stockouts: Yup.string(),
    forcasting_option: Yup.string(),
    seasonal_black_friday: Yup.string(),
    days: Yup.string(),
    months: Yup.string(),
    start_duration_period: Yup.string().nullable(),
    end_duration_period: Yup.string().nullable(),
    percentage: Yup.string(),
  })

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    setFieldValue,
    handleSubmit,
    handleBlur,
    setFormikState,
    resetForm,
  } = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      use_stockouts: false, // boolean
      forcasting_option: 'recent_sales_trends', //select
      seasonal_black_friday: false, //boolaen
      days: '15', //text
      months: '3',
      period_duration: 3,
      // start_duration_period:'',
      // end_duration_period:'',
      percentage: '10',
    },
    onSubmit: (values, helpers) => {
      console.log('values =====.', values)
      dispatch(
        setForcastSettingAsync({
          ...values,
          user_platform: Number(selectedStore),
          period_duration: [1, 3, 6, 12, 24, 36].includes(Number(values.period_duration))
            ? Number(values.period_duration)
            : customPeriodDuration * 12,
        })
      )
        .then((res) => {
          console.log('forcast setting res', res.payload)
          if (res.payload?.status == 201) {
            successToast(res.payload?.message)
          }
          if (res.payload?.status == 404) {
            warningToast(JSON.stringify(res.payload?.data?.error))
          }
          helpers.setSubmitting(false)
        })
        .catch((error) => {
          console.log('error', error)
          helpers.setSubmitting(false)
        })
    },
  })
 
  useEffect(() => {
    if(params?.platformId){
        setLoading(true)
        dispatch(getForecastSettingAsync({user_platform_id: params?.platformId}))
          .then((res) => {
           
            if (res.payload?.status == 200) {
              if (res.payload?.data?.period_duration > 36) {
                setFormikState((pre) => {
                  return {...pre, values: {...res.payload?.data, period_duration: 'customRange'}}
                })
                setCustomPeriodDuration(res.payload?.data?.period_duration / 12)
                setShowCustomPeriodDuration(true)
              } else {
                setFormikState((pre) => {
                  return {...pre, values: {...res.payload?.data}}
                })
              }
              setSelectedStore(res.payload?.data?.user_platform)
            }
            if (res.payload?.status == 500) {
              warningToast(res.payload?.data?.error)
            }
            setLoading(false)
          })
          .catch(() => {
            setLoading(false)
          })
    }
  }, [params])

  if (loading) return <Loader />

  return (
    <div>
      <div className='row justify-content-center'>
        <div className='col-md-8 col-sm-8 col-12 '>
          <form onSubmit={handleSubmit}>
              <div className='shadow-sm rounded p-4 mt-4'>
                <div className='p-5'>
                  <AppSwitchInput
                    key={'notify mail'}
                    isChecked={values.seasonal_black_friday}
                    label='Apply seasonal increase for non-seasonal products for Black Friday and Christmas'
                    onChange={handleChange}
                    otherProps={{
                      name: 'seasonal_black_friday',
                      onBlur: handleBlur,
                    }}
                  />
                  {/* {touched.notify_enable && errors.notify_enable && (
                    <div className='text-danger'>
                      <span role='alert'>{errors.notify_enable}</span>
                    </div>
                  )} */}
                </div>
                <div className='p-5'>
                  <AppSwitchInput
                    isChecked={values.use_stockouts}
                    label='Use Stockout History'
                    onChange={handleChange}
                    otherProps={{
                      name: 'use_stockouts',
                      onBlur: handleBlur,
                    }}
                  />
                </div>

                <div className='d-flex align-items-center p-5'>
                  <div className='col-6'>
                    <label className='form-label d-flex align-items-center required' htmlFor=''>
                      Forecasting Basis &nbsp; <CustomInfoToolTip msg='Set Forecast option' />
                    </label>
                  </div>
                  <div className='col-6'>
                    <select
                      required
                      name='forcasting_option'
                      value={values.forcasting_option}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className='form-select form-select-sm '
                    >
                      <option value=''>Select One</option>
                      <option value='last_sales'>Past Sales</option>
                      <option value='recent_sales_trends'>Recent Sales & Trends</option>
                      <option value='seasonal'>Seasonality</option>
                    </select>
                  </div>
                </div>
                {/* {touched.lead_time && errors.lead_time && (
                  <div className='text-danger'>
                    <span role='alert'>{errors.lead_time}</span>
                  </div>
                )} */}

                {values.forcasting_option === 'recent_sales_trends' ? (
                  <div className='p-5'>
                    <div className='card'>
                      <div
                        className='card-header justify-content-end ribbon ribbon-start '
                        style={{minHeight: 0}}
                      >
                        <div className='ribbon-label bg-info shadow ms-1'>
                          Recent Sales & Trends
                        </div>
                      </div>
                      <div className='border border-info rounded'>
                        <div className='p-5 pb-0 mt-2'>
                          <p className='text-info fw-semibold fs-8' > <i className="bi bi-info-circle-fill fs-4 text-info fw-bold me-2"></i>
                            Forcasting for non-seasonal items. Computed from average sales during
                            the period&nbsp;adjusted for the trend if/when a sales pattern is recognized for
                            the last{' '}
                            {/* <input
                            type='text'
                            name='months'
                            value={values.months}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className='form-control form-control-sm w-50px d-inline-block required'
                          />{' '} */}
                            consucutive months.
                          </p>
                        </div>
                        <div className='d-flex align-items-center py-3 px-5'>
                          <div className='col-6'>
                            <label
                              className='form-label d-flex align-items-center required mb-0'
                              htmlFor=''
                            >
                              Select Recent Sales Duration &nbsp;{' '}
                              <CustomInfoToolTip msg='Select Recent Sales Duration' />
                            </label>
                          </div>
                          <div className='col-6'>
                            <select
                              className='form-select form-select-sm fw-bold'
                              name='period_duration'
                              value={values.period_duration}
                              onChange={(ev) => {
                                if (ev.target.value == 'customRange') {
                                  setShowCustomPeriodDuration(true)
                                  setCustomPeriodDuration(0)
                                } else {
                                  setShowCustomPeriodDuration(false)
                                  // handleMonthRangeSelect(ev.target.value)
                                }
                                handleChange(ev)
                              }}
                            >
                              <option value=''>Select A Period</option>
                              <option value='1'>Last Month</option>
                              <option value='3'>Last 3 Months</option>
                              <option value='6'>Last 6 Months</option>
                              <option value='12'>Last Year</option>
                              <option value='24'>Last 2 Year</option>
                              <option value='36'>Last 3 Year</option>
                              <option value='customRange'>Custom Range</option>
                            </select>
                            {showCustomPeriodDuration ? (
                              <input
                                placeholder='Set custom period yearly'
                                type='text'
                                maxLength={2}
                                required
                                max={99}
                                name='period_duration'
                                value={customPeriodDuration > 0 ? customPeriodDuration + '' : ''}
                                className='form-control form-control-sm mt-2'
                                onChange={(ev) => {
                                  if (
                                    isNaN(Number(ev.target.value)) ||
                                    Number(ev.target.value) < 0 ||
                                    ev.target.value === 'e'
                                  ) {
                                    return
                                  } else {
                                    setCustomPeriodDuration(Number(ev.target.value))
                                  }
                                }}
                              />
                            ) : null}
                          </div>
                        </div>
                        <div className='d-flex align-items-center py-3 px-5'>
                          <div className='col-6'>
                            <label
                              className='form-label d-flex align-items-center required mb-0'
                              htmlFor=''
                            >
                              Set Trends Consecutive Months &nbsp;{' '}
                              <CustomInfoToolTip msg='Set Trends Consecutive Months' />
                            </label>
                          </div>
                          <div className='col-6'>
                            <input
                              type='text'
                              required
                              name='months'
                              value={values.months}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className='form-control form-control-sm'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {values.forcasting_option === 'last_sales' ? (
                  <div className='p-5'>
                    <div className='card'>
                      <div
                        className='card-header justify-content-end ribbon ribbon-start '
                        style={{minHeight: 0}}
                      >
                        <div className='ribbon-label bg-info shadow ms-1'>Past Sales</div>
                      </div>
                      <div className='border border-info rounded'>
                        <div className='p-5 pb-0 fw-bold mt-2'>
                          <p className='text-info fw-semibold fs-8' > <i className="bi bi-info-circle-fill fs-4 text-info fw-bold me-2"></i>
                            Forcasting for non-seasonal items. Computed from average sales for the
                            last days. If "Use Stockout History" is enabled , use last 15 days when
                            the item had available stock
                          </p>
                        </div>
                        <div className='d-flex p-5 pt-0 align-items-center'>
                          <div className='col-4'>
                            <label
                              className='form-label d-flex align-items-center required mb-0'
                              htmlFor=''
                            >
                              Set Days Sales &nbsp; <CustomInfoToolTip msg='Set Days for Past Sales' />
                            </label>
                          </div>
                          <div className='col-6'>
                            <input
                              type='text'
                              name='days'
                              required
                              value={values.days}
                              onChange={handleChange}
                              className='form-control form-control-sm mt-2'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {values.forcasting_option === 'seasonal' ? (
                  <div className='p-5'>
                    <div className='card'>
                      <div
                        className='card-header justify-content-end ribbon ribbon-start '
                        style={{minHeight: 0}}
                      >
                        <div className='ribbon-label bg-info shadow ms-1'>Seasonality</div>
                      </div>
                      <div className='border border-info rounded'>
                        <div className='p-5 pb-0 fw-bold mt-2'>
                          <p className='text-info fw-semibold fs-8' > <i className="bi bi-info-circle-fill fs-4 text-info fw-bold me-2"></i>
                            Forcasting for non-seasonal items. Computed from previous years periods
                            and takes into account year-over-year growth when sales history is
                            available. The year-over-year growth/decline is limited to % to avoid
                            the over forecasting.
                          </p>
                          <p className='text-info fw-semibold fs-8' > 
                            When the item has less than 12 months of sales history the alogrithm
                            falls back to the top down forecasting for missing months. Its computed
                            from seasonal category (or total) forecast and item contribution
                            percentage to the category based on last 2 months.
                          </p>
                        </div>
                        <div className='d-flex p-5 pt-0 align-items-center'>
                          <div className='col-6'>
                            <label
                              className='form-label d-flex align-items-center required mb-0'
                              htmlFor=''
                            >
                              Set Seasonal Sale Percentage &nbsp;{' '}
                              <CustomInfoToolTip msg='Add Default lead time' />
                            </label>
                          </div>
                          <div className='col-6'>
                            <input
                              type='text'
                              name='percentage'
                              value={values.percentage}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className='form-control form-control-sm mt-2'
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className='d-flex justify-content-end'>
                  {/* {values.forcasting_option === 'last_sales' ? (
                    <div className='p-5 border rounded col-6'>
                      <p>
                        Forcasting for non-seasonal items. Computed from average sales for the last{' '}
                        <input
                          type='text'
                          name='days'
                          value={values.days}
                          onChange={handleChange}
                          className='form-control form-control-sm w-50px d-inline-block'
                        />{' '}
                        days. If "Use Stockout History" is enabled , use last 15 days when the item
                        had available stock
                      </p>
                    </div>
                  ) : null} */}
                  {/* {values.forcasting_option === 'recent_sales_trends' ? (
                    <div className='p-5 border rounded col-6'>
                      <p>
                        Forcasting for non-seasonal items. Computed from average sales during the
                        perion
                        <div className='d-inline-flex align-items-center mx-2'>
                          <i className='bi bi-calendar2-range-fill text-info fs-3'></i>
                          <p className='mb-0 mx-1 text-info fw-bold'>{ `${moment(
                            startOfMonth(subMonths(today, isNaN(Number(values.period_duration)) ? customPeriodDuration*12 :   Number(values.period_duration))).toString()
                          )
                            .utc(true)
                            .format('MMM-YY')} - ${moment(
                            startOfMonth(subMonths(today, 1)).toString()
                          )
                            .utc(true)
                            .format('MMM-YY')} `}</p>
                        </div>
                        adjusted for trend if/when a sales pattern is recognized for the last{' '}
                        <input
                          type='text'
                          name='months'
                          value={values.months}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className='form-control form-control-sm w-50px d-inline-block required'
                        />{' '}
                        consucutive months.
                      </p>
                    </div>
                  ) : null} */}

                  {/* {values.forcasting_option === 'seasonal' ? (
                    <div className='p-5 border rounded col-6'>
                      <p>
                        Forcasting for non-seasonal items. Computed from previous years periods and
                        takes into account year-over-year growth when sales history is available. The
                        year-over-year growth/decline is limited to +/-{' '}
                        <input
                          type='text'
                          name='percentage'
                          value={values.percentage}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className='form-control form-control-sm w-50px d-inline-block'
                          required
                        />{' '}
                        % to avoid the over forecasting.
                      </p>
                      <p>
                        When the item has less than 12 months of sales history the alogrithm falls
                        back to the top down forecasting for missing months. Its computed from
                        seasonal category (or total) forecast and item contribution percentage to the
                        category based on last 2 months.
                      </p>
                    </div>
                  ) : null} */}
                </div>

                <div className='p-5 d-flex justify-content-end '>
                  <button disabled={isSubmitting} type='submit' className='btn btn-sm btn-info'>
                    {isSubmitting ? 'Saving...' : 'Save Settings'}
                  </button>
                </div>
              </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default IndivisualForecastSetting
