import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import {getLoggedInUserDetailAsync, loginAsync, registrationAsync} from '../actions/authActions'
import {USER_DATA} from '../../_interfaces/auth.interface'
import {getActivePlatformsAsync} from '../actions/platformActions'
import {Platform} from '../../_interfaces/platform.interface'

const initialState: {
  activePlatformLoader: boolean
  activeIntegrations: Platform[]
} = {
  activeIntegrations: [],
  activePlatformLoader: false,
}

const integrationSlice = createSlice({
  name: 'integrationSlice',
  initialState,
  extraReducers: (builder) => {
    // getLoggedInUserDetailAsync end
    builder.addMatcher(isAnyOf(getActivePlatformsAsync.pending), (state) => {
      console.log('getActivePlatformsAsync is in pending state ')
      state.activePlatformLoader = true
    })
    builder.addMatcher(isAnyOf(getActivePlatformsAsync.rejected), (state) => {
      console.log('getActivePlatformsAsync is in rejected state ')
      state.activePlatformLoader = false
    })
    builder.addMatcher(isAnyOf(getActivePlatformsAsync.fulfilled), (state, action) => {
      if (action.payload?.data) {
        state.activeIntegrations = action.payload.data
      }
      state.activePlatformLoader = false
    })
    // getLoggedInUserDetailAsync end
  },
  reducers: {},
})

// export const {} = integrationSlice.actions

export default integrationSlice.reducer
