import React, { FC, useState } from 'react'
import { CatalogType, Inventory } from '../../../_interfaces/catalog.interface'
import { useAppDispatch } from '../../../redux/storeHooks'
import { setSeasonDataAndLeadTimeOfProductAsync } from '../../../redux/actions/catalogActions'
import useAppToast from '../../../hooks/useAppToast'

type ManageLeadTimeProps = {
    // row: CatalogType
    row:Inventory
}

const ManageLeadTime:FC<ManageLeadTimeProps> = ({row}) => {

    const dispatch  = useAppDispatch()
    const { successToast } = useAppToast()
    const [leadTimeData, setLeadTimeData] = useState(row.lead_time?? '')

    const updateLeadTime = () => {
        dispatch(setSeasonDataAndLeadTimeOfProductAsync({rowId:row.id, data:{lead_time:leadTimeData}}))
        .then((res)=>{
            console.log('saved lead time date', res.payload);
            successToast(res.payload?.message)
        })
    }

  return (
    <div>
    <div className='row align-items-center'>
      <div className='col-8' >
        <input className='form-control form-control-sm h-20px col-8' maxLength={3} value={leadTimeData} type='text' onChange={(ev)=>{
          if(isNaN(Number(ev.target.value))){
            return
          }
          setLeadTimeData(ev.target.value)
          }} />
      </div>
      <div className=' col-4'>
        <button className='btn btn-sm fs-8 btn-link' title='Save Lead Time' onClick={updateLeadTime} >
          {/* Save  */}
           <i className='bi bi-check-square-fill text-success cursor-pointer fs-2 h-100 ms-2'></i>
        </button>
      </div>
    </div>
  </div>
  )
}

export default ManageLeadTime