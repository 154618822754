import React from 'react'
import DataTable, {TableColumn} from 'react-data-table-component'

const Reportings = () => {
  type Report = {
    name: string
    date: string
    status: 'active' | 'inactive'
  }

  const data: Report[] = [
    {
      name: 'Report 1',
      date: '09-09-24',
      status: 'inactive',
    },
    {
      name: 'Report 1',
      date: '09-09-24',
      status: 'active',
    },
  ]

  const columns: TableColumn<Report>[] = [
    {
      id: 1,
      name: 'Report Name',
      cell: (item) => <p>{item.name}</p>,
    },
    {
      id: 2,
      name: 'Reporting Date',
      cell: (item) => <p>{item.date}</p>,
    },
    {
      id: 3,
      name: 'Report Name',
      cell: (item) =>
        item.status == 'active' ? (
          <p className='badge badge-success px-3'>Active</p>
        ) : (
          <p className='badge badge-danger'>Inactive</p>
        ),
    },
  ]

  return (
    <div>
      <div className='card'>
        <div className='card-header'>
          <div className='card-title'>Reportings</div>
          <div className='card-toolbar'>
            <div className='d-flex ' >
                <select className='form-select form-select-sm' aria-label='Select example'>
                <option className='bg-secondary fw-bold ' >Select Status</option>
                <option value='1'>Active</option>
                <option value='2'>Inactive</option>
                </select>
                <button className='btn btn-sm btn-info ms-2'>Reset</button>
            </div>
          </div>
        </div>
        <div className='card-body'>
          <DataTable
            columns={columns}
            data={data}
            pagination
            highlightOnHover
            customStyles={{
              head: {
                style: {
                  fontSize: '1.2em',
                  fontWeight: 'bold',
                },
              },

              table: {
                style: {
                  minHeight: '58vh',
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Reportings
