import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import {getLoggedInUserDetailAsync, loginAsync, registrationAsync} from '../actions/authActions'
import {USER_DATA} from '../../_interfaces/auth.interface'

type LOGIN_PAYLOAD = {
  isLoggedIn: boolean
  userData: USER_DATA|null
  status?: number
}

const initialState: {
  authLoading: boolean
  isLoggedIn: boolean
  userDetail: USER_DATA
} = {
  authLoading: false,
  isLoggedIn: false,
  userDetail: {
    firstName:'',
    lastName:'',
    email:'',
    userId:null
  },
}

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  extraReducers: (builder) => {
    // getLoggedInUserDetailAsync end
    builder.addMatcher(isAnyOf(getLoggedInUserDetailAsync.pending), (state) => {
      console.log('getLoggedInUserDetailAsync is in pending state ')
    })
    builder.addMatcher(isAnyOf(getLoggedInUserDetailAsync.rejected), (state) => {
      console.log('getLoggedInUserDetailAsync is in rejected state ')
    })
    builder.addMatcher(isAnyOf(getLoggedInUserDetailAsync.fulfilled), (state, action) => {
      console.log('register fulfilled', action.payload)
      if(action.payload?.data?.id){
        state.userDetail.firstName = action.payload?.data?.first_name
        state.userDetail.lastName = action.payload?.data?.last_name 
        state.userDetail.email = action.payload?.data?.email
        state.userDetail.userId = action.payload?.data?.id 
      }
      state.authLoading = false
    })
    // getLoggedInUserDetailAsync end
  },
  reducers: {
    // use this reducer for login logout
    setLoggingData: (state, action: {payload: LOGIN_PAYLOAD; type: string}) => {
      state.isLoggedIn = action.payload?.isLoggedIn
      if(action.payload.userData){
        state.userDetail = action.payload.userData
      }
    },
  },
})

export const {setLoggingData} = authSlice.actions

export default authSlice.reducer
