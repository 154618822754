import {ToastOptions, toast} from 'react-toastify'


const useAppToast = () => {
  const successToast = (message: string, options?:ToastOptions ) => toast.success(message, {type: 'success', ...options })
  const warningToast = (message: string, options?:ToastOptions ) => toast.warning(message, {type: 'warning', ...options})
  const errorToast = (message: string, options?:ToastOptions ) => toast.error(message, {type: 'error',...options})

  return {successToast, warningToast, errorToast}
}

export default useAppToast
