import {ApexOptions} from 'apexcharts'
import React, {useEffect, useState} from 'react'
import ReactApexChart from 'react-apexcharts'
import DataTable, {TableColumn} from 'react-data-table-component'
import {useAppDispatch, useAppSelector} from '../../../redux/storeHooks'
import {getAllSalesAsync} from '../../../redux/actions/productActions'
import {Sales_Record} from '../../../_interfaces/catalog.interface'
import {Link} from 'react-router-dom'
import {
  getActivePlatformsAsync,
  getDashboardGraphAsync,
  getPlatformSettingsAsync,
} from '../../../redux/actions/platformActions'
import {Spinner} from 'react-bootstrap'
import { getAllLocationsAsync } from '../../../redux/actions/catalogActions'
import Loader from '../../../utils/Loader'
import useAppToast from '../../../hooks/useAppToast'
import CustomInfoToolTip from '../../components/CustomInfoToolTip'

const Dashboard = () => {
  const [loading, setLoading] = useState(false)
  const [initialLoading, setInitialLoading] = useState(true)
  const [salesData, setSalesData] = useState<Sales_Record[]>([])
  const [series, setSeries] = useState([])
  const [labels, setLabels] = useState([])
  const { warningToast,successToast } = useAppToast()
  const {activeIntegrations} = useAppSelector((state) => state.integration)
  const {allActiveLocations} = useAppSelector((state) => state.catalog)
  const [searchVal, setSearchVal] = useState('')

  const [filters, setFilters] = useState({
    store_id:'',
    location_id:'all',
    page: 1,
    page_limit: 10,
    search:''
  })
  const dispatch = useAppDispatch()

  useEffect(() => {
    setInitialLoading(true)
    Promise.all([
       
      dispatch(getActivePlatformsAsync())
      .then((res)=>{
        console.log('connected platforms', res.payload);
        if(res.payload?.data?.length){
          setFilters((pre)=>({...pre, store_id:res.payload?.data[0]?.id }))
        }
      }),
      dispatch(getAllLocationsAsync())
    ]).finally(()=>{
      setInitialLoading(false)
    })
     
  }, [])

  const getDashboardDataAsync = async (filterData) => {
    setLoading(true)

    if(!filterData.store_id) {
      setLoading(false)
      return
    }

    const [graphData, saleData] = await Promise.all([
      dispatch(getDashboardGraphAsync({...filterData})),
      dispatch(getAllSalesAsync({...filterData})),
    ])

    if (graphData.payload?.data?.length) {
      const titles = graphData.payload?.data?.map((item) => item?.product_title)
      const graphSeries = graphData.payload?.data?.map((item) => item?.total_sold)
      console.log('graph data', titles, graphSeries)
      setLabels(titles)
      setSeries(graphSeries)
    }

    if (saleData.payload?.data?.length) {
      setSalesData(saleData.payload?.data)
    }else{
      setSalesData([])
    }

    if(saleData.payload?.data?.status==500){
      warningToast(saleData?.payload?.data?.error)
    }

    setLoading(false)
    // Promise.all([
    //   dispatch(getAllSalesAsync({...filterData}))
    //   .then((response) => {
    //     if (response.payload?.data?.length) {
    //       setSalesData(response.payload?.data)
    //     }
    //   })
    //   .catch(),
    //   dispatch(getDashboardGraphAsync({ store_id:filterData.store_id}))
    //   .then((res)=>{
    //     console.log('graph data', res.payload);
    //   })
    //   .catch()
    // ]).finally(()=>{
    //   setLoading(false)
    // })
  }

  useEffect(() => {
    getDashboardDataAsync(filters)
  }, [filters])

  const options: ApexOptions = {
    chart: {
      width: '60%',
      height: '100%',
      type: 'donut',
      // toolbar:{
      //   show:true
      // },
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 5,
        left: 0,
        blur: 3,
        color: 'slateblue',
        opacity: 0.35,
      },
    },
    labels: labels,
    dataLabels: {
      enabled: true,
      formatter: (val, opts) => {
        return series[opts.seriesIndex]
      },
    },
    legend: {
      show: true,
      position: 'left',
      fontWeight: 'bold',
      itemMargin: {
        vertical: 8,
      },
      markers: {
        height: 0,
        width: 0,
      },
      formatter: (val, opts) => {
        return val + ' - ' + series[opts.seriesIndex]
      },
    },
    theme: {
      monochrome: {
        enabled: true,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
            },
            value: {
              show: true,
            },
            total: {
              showAlways: true,
              show: true,
              label: 'Total Sold',
            },
          },
        },
        dataLabels: {
          offset: -5,
        },
      },
    },
    grid: {
      position: 'back',
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 100,
      },
    },
    responsive: [
      {
        breakpoint: 576,
        options: {
          chart: {
            width: 260,
            height: 300,
            type: 'donut',
          },

          legend: {
            position: 'top',
            horizontalAlign: 'left',
            fontSize: 10,
            fontWeight: 'bold',
            itemMargin: {
              vertical: 3,
            },
            markers: {
              height: 0,
              width: 0,
            },
          },

          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: false,
                },
              },
              dataLabels: {
                offset: -5,
              },
            },
          },
        },
      },

      {
        breakpoint: 992,
        options: {
          chart: {
            width: 350,
            height: 350,
            type: 'donut',
          },

          legend: {
            position: 'top',
            horizontalAlign: 'left',
            fontWeight: 'bold',
            itemMargin: {
              vertical: 5,
            },
            markers: {
              height: 0,
              width: 0,
            },
          },

          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: false,
                },
              },
              dataLabels: {
                offset: -5,
              },
            },
          },
        },
      },
    ],
  }

  const columns: TableColumn<Sales_Record>[] = [
    {
      name: 'Title',
      cell: (row) => {
        return (
          <div>
            <p className='fs-5 text-success fw-bold mb-0'>{row?.title}</p>
          </div>
        )
      },
    },
    {
      name: 'Vendor',
      cell: (row) => row?.vendor,
    },
    {
      name: <div className='d-flex ' >Replenish &nbsp;<CustomInfoToolTip msg='Amount needed in the current inventory' /> </div> ,
      cell: (row) => row?.replenish,
    },
    {
      name: 'SKU',
      cell: (row) => row?.sku,
    },
    {
      name: <div className='d-flex' >Warehouse</div>,
      cell: (row) => row?.location,
    },
    {
      name:  <div className='d-flex' >Current Quantity &nbsp;<CustomInfoToolTip msg='Currently Available Quantity' /></div>,
      cell: (row) => row?.current_available,
    },
    {
      name: <div className='d-flex' >Predicted Quantity &nbsp;<CustomInfoToolTip msg='Predicted quantity needed for product in the warehouse' /></div>,
      cell: (row) => row?.predicted_quantity,
    },
  ]

  if(initialLoading) return <Loader/>
   
  return (
    <div>
      <div className='card card-custom'>
        <div className='card-header'>
          <h3 className='card-title'>Dashboard</h3>
          <div className='card-toolbar'>
            <div className='d-flex align-items-center' >
              <div>
                <label className='form-label fs-8 mb-0' >Select Store</label>
              <select
                className='form-select form-select-sm border border-2 border-secondary'
                value={filters.store_id}
                onChange={(ev) => {
                  setFilters((pre) => ({...pre, store_id: ev.target.value}))
                }}
              >
                <option value=''>Select Store</option>
                {activeIntegrations.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.friendly_name}
                    </option>
                  )
                })}
              </select>

              </div>
              <div>
              <label className='form-label fs-8 mb-0' >Select Location</label>
              <select
                onChange={(ev)=>{
                  setFilters(pre=>({...pre, location_id:ev.target.value}))
                }}
                className='form-select form-select-sm border border-2 border-secondary ms-2 w-200px'
              >
                <option value="all">Select Location &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
                {
                  allActiveLocations.map((item)=>{
                    return <option key={item.location_id} value={item.location_id}>{item.name}</option>
                    
                  })

                }
              </select>
              </div>
            </div>
          </div>
        </div>
        <div className='card-body'>
          <div className='row justify-content-between'>
            <div className='col-12 border-sm-end border-end-2 border-secondary'>
              {loading && !series.length ? (
                <div className='d-flex align-items-center justify-content-center p-5'>
                  <Spinner animation='border' />
                </div>
              ) : null}
              { series.length ? (
                <div>
                  <h4>Top Sold Products</h4>
                  { loading ? 
                   <div className='d-flex align-items-center justify-content-center p-5'>
                   <Spinner animation='border' />
                 </div> : 
                  <div id='chart' className=''>
                    <ReactApexChart
                      options={options}
                      series={series}
                      type='donut'
                      height={'300px'}
                    />
                  </div>}
                  <div className='separator border-secondary my-5'></div>
                </div>
              ) : null 
              }
            </div>

            {/* <div className='col-md-6'>
              <h4>Stock Retail By Vendor</h4>
              <div id='chart' className='ps-2'>
                <ReactApexChart options={options2} series={series} type='pie' height={'300px'} />
              </div>
            </div> */}
          </div>
          <div className='row shadow'>
            <div className='card-header'>
              <div className='card-title'>Top Variants To Replenish</div>
              <div className="card-toolbar">
                <input 
                  type="text" 
                  placeholder='Search' 
                  className='form-control form-control-sm' 
                  value={searchVal} 
                  onChange={(ev)=>setSearchVal(ev.target.value)} 
                  onKeyDown={(ev)=>{
                    if(ev.key==='Enter'){
                      setFilters((pre)=>({...pre, search:searchVal }))
                    }
                  }}
                />
              </div>
            </div>
            <DataTable
              responsive
              highlightOnHover
              columns={columns}
              data={salesData}
              pagination
              persistTableHead
              onChangePage={(page, totalRows) => {
                setFilters((pre) => ({...pre, page: page}))
              }}
              onChangeRowsPerPage={(page, totalRows) => {
                setFilters((pre) => ({...pre, page_limit: page}))
              }}
              progressPending={loading}
              customStyles={{
                table: {
                  style: {
                    minHeight: '40vh',
                  },
                },
              }}
              noDataComponent={
                <div>
                  <div className=' mt-8 alert alert-info d-flex align-items-center p-8 mb-10'>
                    <span className='svg-icon svg-icon-2hx svg-icon-info me-3'>
                      <i className='bi bi-info-circle fs-2x text-info'></i>
                    </span>

                    <div className='d-flex flex-column'>
                      <h4 className='mb-2'>There is no active integrations</h4>
                      <span className='text-muted'>
                        For create new integrations{' '}
                        <Link className='fw-bold' to={'/integrations'}>
                          Click Here <i className='bi bi-box-arrow-up-right text-primary fs-2'></i>
                        </Link>
                        .
                      </span>
                    </div>
                  </div>
                </div>
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
