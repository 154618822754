import { FC, useEffect, useState } from "react"
import { MenuComponent } from "../../../_metronic/assets/ts/components"
import clsx from "clsx"
import { useAppDispatch } from "../../../redux/storeHooks"
import { CatalogType } from "../../../_interfaces/catalog.interface"
import useAppToast from "../../../hooks/useAppToast"
import { setSeasonDataAndLeadTimeOfProductAsync } from "../../../redux/actions/catalogActions"

type PopupMenuProps = {
    selectedSeasons:string[]
    rowIndex:number
    onChangeSelectedMonths:(months:string[])=>void
    rowData: CatalogType

}

/* eslint-disable jsx-a11y/anchor-is-valid */

const SelectSeasonMonths:FC<PopupMenuProps> = ({
     selectedSeasons,
     rowIndex,
     onChangeSelectedMonths,
     rowData
}) =>  {

    const [months, setMonths] = useState<string[]>(selectedSeasons)
    const [loading, setLoading] = useState<boolean>(false)
    const {successToast, warningToast} = useAppToast()
    const dispatch  = useAppDispatch()

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    const saveSeasonData = () => {
        console.log(rowData.is_seasonal, rowData.seasonal_months);
        setLoading(true)
        dispatch(setSeasonDataAndLeadTimeOfProductAsync({rowId:rowData.id, data:{ is_seasonal:rowData.is_seasonal, seasonal_months:rowData.seasonal_months }}))
        .then((res)=>{
            console.log('season saved response', res.payload);
            if(res.payload?.status==200){
                
                successToast(res.payload?.message)
            }
            if(res.payload?.status==400){
                warningToast(res.payload?.data?.message)
            }

            if(res.payload?.status==500){
                warningToast(res.payload?.data?.error)
            }
            setLoading(false)
        })
        .catch(()=>{
            setLoading(false)
        })
        
    }
    

    return (
      <div className='menu menu-sub menu-sub-dropdown w-200px' data-kt-menu='true' >
        {/* w-250px w-md-300px */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Select Seasons</div>
        </div>
          
  
        <div className='separator border-gray-200'></div>
  
        <div className='p-2'>
            <div className="row g-1">
                {
                    ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
                    .map((month)=>{
                        let isMonthSelected = months.find((m)=>(m===month))
                        console.log('isMonthSelected', isMonthSelected)

                        return(
                                <div key={month+rowIndex} className="col-4">
                                    <button className={clsx("btn btn-sm fs-8", isMonthSelected ? 'btn-info' : 'btn-outline' )} onClick={()=>{
                                        let shadow = [...months]
                                        if(isMonthSelected){
                                            let filtered = shadow.filter((m)=>(m!==month))
                                            setMonths(filtered)
                                            onChangeSelectedMonths(filtered)
                                        }else{
                                            shadow.push(month)
                                            setMonths(shadow)
                                            onChangeSelectedMonths(shadow)
                                        }
                                    }} >{month}</button>
                                </div>
                        )
                    })
                }
            </div>
            <div className="d-flex justify-content-center mt-4">
                <button disabled={loading} className="btn btn-sm btn-light-success align-self-center fs-8"
                onClick={()=>{
                    saveSeasonData()
                }}
                >{ loading ? 'Saving...' : 'Apply Changes'}</button>
            </div>
        </div>
      </div>
    )
  }

  export default SelectSeasonMonths
  