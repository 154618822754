import React, {FC, useEffect, useState} from 'react'
import {useAppDispatch} from '../../../redux/storeHooks'
import {
  deleteActivePlatformsAsync,
  getActivePlatformsAsync,
} from '../../../redux/actions/platformActions'
import {Platform} from '../../../_interfaces/platform.interface'
import {KTSVG} from '../../../_metronic/helpers'
import ConnectPlatformModal from '../integrations/components/ConnectPlatformModal'
import ActivePlatformCard from './components/ActivePlatformCard'
import DeleteConfirmationModal from './components/DeleteConfirmationModal'
import useAppToast from '../../../hooks/useAppToast'
import Loader from '../../../utils/Loader'
import { Link } from 'react-router-dom'

const ActivePlatforms: FC = () => {
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const [editConnectionData, setEditConnectionData] = useState<Platform | null>(null)
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
  const toast = useAppToast()
  const [loading, setLoading] = useState(false)
  const [activePlatforms, setActivePlatforms] = useState<Platform[]>([])
  const [deleting, setDeleting] = useState<boolean>(false)

  const dispatch = useAppDispatch()

  const getActivePlatforms = async () => {
    setLoading(true)
    dispatch(getActivePlatformsAsync()).then((res) => {
      if(res.payload?.data?.length){
        setActivePlatforms(res.payload?.data)
      }
      setLoading(false)
    })
    .catch((error)=>{
      setLoading(false)
      console.log('error', error)
    })
  }

  useEffect(() => {
    getActivePlatforms()
  }, [])

  if(loading) return <Loader/>

  return (
    <div>
      <div className='d-flex justify-content-between mb-8  '>
      <h1>Active Integrations</h1>
        <Link to={'/integrations'} className='btn btn-sm btn-info '>Add New Platform</Link>
      </div>
      <div className='row g-5 gx-4 g-xl-2 my-2'>
        { activePlatforms.length ? activePlatforms.map((activePlatform) => {
          return (
            <ActivePlatformCard
              key={'active_platform' + activePlatform.id}
              activePlatform={activePlatform}
              onClickEdit={() => {
                setEditConnectionData(activePlatform)
                setOpenEditModal(true)
              }}
              onClickDelete={() => {
                setEditConnectionData(activePlatform)
                setOpenDeleteConfirmation(true)
              }}
            />
          )
        }) : 
        <div className='row justify-content-center' >
          <div className='col-8 ' >
              <div className=" mt-8 alert alert-info d-flex align-items-center p-8 mb-10">
              <span className="svg-icon svg-icon-2hx svg-icon-info me-3"><i className="bi bi-info-circle fs-2x text-info"></i></span>
              
              <div className="d-flex flex-column">
                <h4 className="mb-2">There is no active integrations</h4>
                <span className='text-muted' >For create new integrations <Link className='fw-bold' to={'/integrations'} >Click Here <i className="bi bi-box-arrow-up-right text-primary fs-2"></i></Link>.</span>
              </div>
              </div>
          </div>
        </div>
        }
      </div>
      <ConnectPlatformModal
        isVisible={openEditModal}
        onClose={() => {
          setOpenEditModal(false)
          setEditConnectionData(null)
        }}
        platformData={editConnectionData}
      />
      <DeleteConfirmationModal
        isOpen={openDeleteConfirmation}
        isProcessing={deleting}
        labelOnProcessingTime='Deleting...'
        onCancel={() => setOpenDeleteConfirmation(false)}
        onAccept={() => {
          if(editConnectionData){
            setDeleting(true)
            dispatch(deleteActivePlatformsAsync({activePlatformId: editConnectionData?.id})).then((res) => {
              console.log('res', res)
              if(res.payload?.status==200){
                toast.successToast(res.payload?.message)
                getActivePlatforms()
                setEditConnectionData(null)
                setOpenDeleteConfirmation(false)
                setDeleting(false)
              }
            })
            .catch(()=>{
              setDeleting(false)
            })
          }
        }}
      />
    </div>
  )
}

export default ActivePlatforms
