import React, {FC} from 'react'
import {Link} from 'react-router-dom'

type BreadCrumb = {
  title: string
  url: string
}

type BreadCrumbsPropsType = {
  breadCrumbs: BreadCrumb[]
  currentActive: string
}

const BreadCrumbs: FC<BreadCrumbsPropsType> = ({breadCrumbs, currentActive}) => {
  return (
    <div>
      <ol className='breadcrumb text-muted fs-6 fw-bold'>
        {breadCrumbs.length
          ? breadCrumbs.map((item) => {
              return (
                <li className='breadcrumb-item pe-1'>
                  <Link to={item.url} className='pe-1'>
                    {item.title}
                  </Link>
                </li>
              )
            })
          : null}
        <li className='breadcrumb-item px-1 text-muted'>{currentActive}</li>
      </ol>
    </div>
  )
}

export default BreadCrumbs
