import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import Dashboard from '../pages/dashboard/Dashboard'
import Integrations from '../pages/integrations/Integrations'
import ActivePlatforms from '../pages/active-platforms/ActivePlatforms'
import Reportings from '../pages/reportings/Reportings'
import ActivePlatformSetting from '../pages/active-platforms/ActivePlatformSetting'
import PlatformProducts from '../pages/platform-product/PlatformProducts'
import Catalog from '../pages/catalog/Catalog'
import Profile from '../pages/profile/Profile'
import CommonSettings from '../pages/common-settings/CommonSettings'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        {/* <Route path='auth/*' element={<Navigate to='/dashboard' />} /> */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />

        {/* Pages */}
        <Route path='dashboard' element={<Dashboard />} />
        <Route path='integrations' element={<Integrations />} />
        <Route path='active-integrations' element={<ActivePlatforms />} />
        <Route path='reportings' element={<Reportings />} />
        <Route path='profile' element={<Profile />} />
        <Route path='platform-setting/:platformId' element={<ActivePlatformSetting />} />
        <Route path='platform-products/:platformId' element={<PlatformProducts />} />
        <Route path='catalog' element={<Catalog />} />
        <Route path='general-settings' element={<CommonSettings />} />



        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}


export {PrivateRoutes}
