import clsx from 'clsx'
import React, {FC, useEffect, useState} from 'react'
import AppSwitchInput from '../../components/AppSwitchInput'
import {useAppDispatch} from '../../../redux/storeHooks'
import {
  getPlatformSettingsAsync,
  updateSettingStatusAsync,
} from '../../../redux/actions/platformActions'
import useAppToast from '../../../hooks/useAppToast'
import {Grouped_Warehouse_Connection} from '../../../_interfaces/platform.interface'
import Loader from '../../../utils/Loader'

const AllWarehousePage: FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const toast = useAppToast()
  const [groupedSettings, setGroupedSettings] = useState<Grouped_Warehouse_Connection[]>([])

  const getWarehouseData = () => {
    setLoading(true)
    dispatch(getPlatformSettingsAsync([]))
      .then((response) => {
        console.log('platform settings------>', response.payload)
        if (response.payload?.data?.length) {
          setGroupedSettings(response.payload?.data)
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getWarehouseData()
  }, [])

  if (loading) return <Loader />

  return (
    <div>
          <div className="row g-5 ">
            {groupedSettings.length ? (
              groupedSettings.map((setting, settingIndex) => {
                return (
                  <div className='card border border-2 mt-12' key={'sectiongroup_' + setting.platform_name}>
                    <div className="card-header ribbon ribbon-start" style={{minHeight:0}} >
                      <div className="ribbon-label bg-info shadow p-2">Connection Name - {setting.friendly_name}</div>  
                    </div>

                    {/* <p className='h2'> Connection Name - {setting.friendly_name}</p> */}
                    <div className="card-body">
                      <div className='row row-cols-1 row-cols-sm-2 row-cols-md-2 gx-2 gy-2 mt-4'>
                        {setting.object_settings.length ? (
                          setting.object_settings.map((item, itemIndex) => {
                            return (
                              <div className='col mt-2' key={'store_location'+setting.friendly_name+item.id} >
                                <div className='card card-custom border border-2'>
                                  <div className='card-body'>
                                    <div className='d-flex justify-content-between align-items-end'>
                                      <p className='h2'>{item.object_data.api_name}</p>
                                      <AppSwitchInput
                                        label=''
                                        isChecked={item.status}
                                        onChange={(ev) => {
                                          let shadow = [...groupedSettings]
                                          let shadowCurrentSetting = {...shadow[settingIndex]}
                                          let currentSetting = [...groupedSettings[settingIndex].object_settings]
                                          let currentItem = {...currentSetting[itemIndex]}
                                          
                                          currentItem.status = ev.target.checked
                                          currentSetting[itemIndex] = currentItem
                                          shadowCurrentSetting.object_settings = currentSetting
                                          shadow[settingIndex] = shadowCurrentSetting
                                          dispatch(
                                            updateSettingStatusAsync({settings_id : item.id})
                                          ).then((res) => {
                                            console.log('systus change res', res.payload)
                                            if (res.payload?.status == 200) {
                                              toast.successToast(res.payload?.message)
                                            }
                                          })
                                          setGroupedSettings(shadow)
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        ) : (
                          <div className='p-4' >
                            <h2>No Warehouse found</h2>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })
            ) : (
              <div className='p-12' >
                <h2>No Settings found</h2>
              </div>
            )}
          </div>
       
    </div>
  )
}

export default AllWarehousePage
