import React, {FC} from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

const CustomInfoToolTip: FC<{msg: string, iconColor?:string }> = ({msg,iconColor}) => {
  return (
    <div>
      <OverlayTrigger overlay={(props)=><Tooltip {...props} > <small className='lh-1' >{msg}</small></Tooltip>}>
        <i className='bi bi-info-circle-fill fs-4' style={{color:iconColor}} ></i>
      </OverlayTrigger>
    </div>
  )
}

export default CustomInfoToolTip
