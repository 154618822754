import React, {FC, useState} from 'react'
import {Platform} from '../../../../_interfaces/platform.interface'
import { Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useAppDispatch } from '../../../../redux/storeHooks'
import { fetIntegrationDataAsync } from '../../../../redux/actions/platformActions'
import useAppToast from '../../../../hooks/useAppToast'

type ActivePlatformCardPropsType = {
  activePlatform: Platform
  onClickEdit: () => void
  onClickDelete: () => void
}

const ActivePlatformCard: FC<ActivePlatformCardPropsType> = ({
  activePlatform,
  onClickDelete,
  onClickEdit,
}) => {

  const dispatch = useAppDispatch()
  const { successToast,warningToast } = useAppToast()
  const [loading, setLoading] = useState(false)

  const fetchStoreData = async (data:any) => {
      setLoading(true)
      dispatch(fetIntegrationDataAsync({id:data}))
      .then((res)=>{
        if(res.payload?.status===200){
          successToast(res.payload?.message)
        }
        if(res.payload?.status===400){
          warningToast(res.payload?.data?.message)
          
        }
        if(res.payload?.status===500){
          warningToast(res.payload?.data?.message)
        }
        setLoading(false)
      })
      .catch(()=>{
        setLoading(false)
        warningToast('Something went wrong')
      })
  }

  return (
    <div className='col-xl-2 col-md-4 col-sm-6 col-12'>
      <div>
        <div className='card border border-3 bg-hover-light-info '>
          <div className='d-inline-flex justify-content-end' >
            <button className='btn btn-sm  bg-white text-hover-white text-hover-danger p-1' onClick={() => onClickDelete()}>
               <i className='bi bi-x-lg text-danger '></i>
             </button>
            
          </div>
          <div className='card-body'>
            <div className='d-flex justify-content-center '>
              <img
                src={activePlatform.logo_url}
                alt={activePlatform.friendly_name}
                className='h-70px'
                loading='lazy'
              />
            </div>
            {/* <div className='separator my-2'></div> */}
            <div className=' text-center mt-4'>
              <label htmlFor='' className='form-label text-muted'>
                Connection Name
              </label>
              <p className='h4 text-truncate lh-1'>{activePlatform.friendly_name}</p>
            </div>

          </div>
          <div className='card-footer border-0 p-3 pb-0' >
            <div className='row'>
              <OverlayTrigger placement='auto' overlay={(props)=><Tooltip {...props}  >{'Store Settings'}</Tooltip>} >
              <Link
                to={`/platform-setting/${activePlatform.id}`}
                className='btn btn-sm btn-outline btn-outline-info rounded-0 col-6'
              >
                <i className="bi bi-gear-fill fs-2"></i>
              </Link>
              </OverlayTrigger>
              <OverlayTrigger placement='auto'  overlay={(props)=><Tooltip {...props}  >{activePlatform?.tooltip ?? 'Your platform data will be re-fetched!' }</Tooltip>} >
                <button className='btn btn-sm btn-outline btn-outline-primary rounded-0 col-6' onClick={()=>fetchStoreData(activePlatform.id)} >
                  {!loading? <i className='bi bi-arrow-clockwise fs-2 fs-bold'></i> :
                  <i className="fa-solid fa-sync fa-spin fs-2 fw-bold"></i>}
                </button>
              </OverlayTrigger>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActivePlatformCard
