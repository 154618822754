import React from 'react'

const Loader = () => {
  return (
    <div className='d-flex justify-content-center align-items-center h-100' >
      <span className='spinner'>
        <span className='spinner-border align-middle fw-bold text-primary' ></span>
      </span>
    </div>
  )
}

export default Loader
