import clsx from 'clsx'
import {KTIcon, KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import { useAppDispatch, useAppSelector } from '../../../../redux/storeHooks'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { getStoreStatusInfoAsync } from '../../../../redux/actions/platformActions'

const itemClass = 'ms-1 ms-md-4'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'

const Navbar = () => {
  const {config} = useLayout()
  const { userDetail } = useAppSelector(state=>state.auth)
  const [storeInfoMsg, setStoreInfoMsg] = useState('')
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getStoreStatusInfoAsync())
      .then((res)=>{
        console.log('stroe status', res.payload.message);
        if(res.payload?.message){
          setStoreInfoMsg(res.payload?.message)
        }
      })
    setInterval(() => {
      dispatch(getStoreStatusInfoAsync())
      .then((res)=>{
        console.log('stroe status', res.payload.message);
        if(res.payload?.message){
          setStoreInfoMsg(res.payload?.message)
        }
      })
    }, 2*60*1000);
  }, [])
  

  return (
    <div className='app-navbar flex-shrink-0'>
      {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTIcon iconName='chart-simple' className={btnIconClass} />
        </div>
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTIcon iconName='element-plus' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTIcon iconName='message-text-2' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div> */}

        <div className='app-navbar-item' >
         { storeInfoMsg ? <OverlayTrigger placement='left' overlay={<Tooltip>{storeInfoMsg}</Tooltip>} >
            <button className="btn btn-icon btn-light bg-white pulse pulse-info me-5">
              {/* <KTSVG
                path="media/icons/duotune/general/gen015.svg"
                className="svg-icon svg-icon-1"
              /> */}
              <i className="fa fa-cog fa-spin fs-1 text-info"></i>
              <span className="pulse-ring"></span>
            </button>
          </OverlayTrigger> : null }
        </div>

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div> */}

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          {/* <img src={toAbsoluteUrl('/media/avatars/300-3.jpg')} alt='' /> */}
          <span className='badge badge-light-primary border border-2 border-primary p-2' >
            <p className='mb-0 fs-1' >{userDetail?.firstName?.charAt(0) + "" + userDetail?.lastName?.charAt(0) }</p>
          </span>
        </div>
        <HeaderUserMenu />
      </div>

      {/* {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )} */}
    </div>
  )
}

export {Navbar}
