import React, {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {AVAILABLE_PLATFORM_AUTH_REQUIREMENT, Platform} from '../../../../_interfaces/platform.interface'
import {useFormik} from 'formik'
import AuthRequirenmentInput from './AuthRequirenmentInput'
import { useAppDispatch } from '../../../../redux/storeHooks'
import { connectPlatformAsync, updatePlatformAsync } from '../../../../redux/actions/platformActions'
import useAppToast from '../../../../hooks/useAppToast'
import { useNavigate } from 'react-router-dom'

type ConnectPlatformModalPropsTypes = {
  isVisible: boolean
  onClose: () => void
  platformData: null | Platform
}

const ConnectPlatformModal: FC<ConnectPlatformModalPropsTypes> = ({
  isVisible,
  onClose,
  platformData,
}) => {

    const [formValues, setFormValues] = useState<{[key:string]:string}>({
      friendly_name:''
    })
    const dispatch = useAppDispatch()
    const toast = useAppToast()
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)

  const handleSubmitAsync = async (event:React.FormEvent<HTMLFormElement> ) => {

    event.preventDefault()
    setLoading(true)
    console.log('formValues', formValues)
    let payload = {...formValues}

    if(platformData){
        payload['platform'] = platformData.id+''
    }else{
      toast.errorToast("Platform detail missing")
      return
    }

    if(!platformData?.credential_id){
     if(platformData?.auth_type=='BASIC'){

       dispatch(connectPlatformAsync({...payload}))
       .then((res)=>{
           console.log('res after connect', res.payload)
           if(res.payload?.status==201){
             toast.successToast(res.payload?.message)
             setFormValues({displayName:''})
             navigate('/active-integrations')
             onClose()
           }
           if(res.payload?.status==400|| res.payload?.status==404){
             toast.warningToast(res.payload?.data?.message)
           }
           if(res.payload?.status==500){
             toast.errorToast(res.payload?.data?.error)
           }
           setLoading(false)
       })
       .catch((error)=>{
           console.log('error', error)
           setLoading(false)
       })
     }else{
      //oauth case
      dispatch(connectPlatformAsync({ friendly_name:formValues['friendly_name'], redirect_url:window.location.origin+'/active-integrations', platform:platformData.id }))
       .then((res)=>{
           console.log('res after oauth connect', res.payload.status)
           if(res.payload?.status==200){
            window.location.href = res.payload.url
           }
          //  if(res.payload?.status==201){
          //    toast.successToast(res.payload?.message)
          //    setFormValues({displayName:''})
          //    onClose()
          //  }
          //  if(res.payload?.status==400|| res.payload?.status==404){
          //    toast.warningToast(res.payload?.data?.message)
          //  }
          //  if(res.payload?.status==500){
          //    toast.errorToast(res.payload?.data?.message)
          //  }
           setLoading(false)
       })
       .catch((error)=>{
           console.log('error', error)
           setLoading(false)
       })
     }
    }else{
      dispatch(updatePlatformAsync({ activePlatformId: platformData.credential_id,  payload:{ platform: platformData?.id, ...formValues}}))
      .then((res)=>{
          console.log('res after connect', res.payload)
          if(res.payload?.status==200){
            toast.successToast(res.payload?.message)
            onClose()
          }
          if(res.payload?.status==400|| res.payload?.status==404){
            toast.warningToast(res.payload?.data?.message)
          }
          if(res.payload?.status==500){
            toast.errorToast(res.payload?.data?.message)
          }
          setLoading(false)
      })
      .catch((error)=>{
          console.log('error', error)
          setLoading(false)
      })
    }
  }

  useEffect(() => {
     if(platformData){
      if(platformData.available_platform_auth_requirement.length){
        platformData.available_platform_auth_requirement.map((item:AVAILABLE_PLATFORM_AUTH_REQUIREMENT)=>{
          setFormValues((pre)=>{
            return {...pre, friendly_name:platformData?.friendly_name ?? '' , [item.name]: item?.value ?? '' }
          })
        })
      }else{
        setFormValues((pre)=>{
          return {...pre, friendly_name:platformData?.friendly_name ?? '' }
        })
      }
     }
  }, [platformData])
  
 

  return (
    <Modal backdrop='static' show={isVisible} onHide={onClose}>
      <Modal.Header closeButton className='h3' >Connect to {platformData?.name}</Modal.Header>
      <form onSubmit={(ev)=> handleSubmitAsync(ev)} >
        <Modal.Body>
            <div>
            <label className='form-label' htmlFor=''>Friendly Name</label>
            <input
                type='text'
                required
                className='form-control form-control-sm '
                placeholder='Friendly Name'
                defaultValue={platformData?.friendly_name}
                value={formValues['friendly_name']}
                onChange={(ev)=>{
                    setFormValues(pre=>{
                        return {...pre, friendly_name:ev.target.value }
                    })
                }}
            />
            </div>
            { platformData?.auth_type=='BASIC' && platformData?.available_platform_auth_requirement.length ? (
            <div className='border border-2 border-secondary rounded p-4 mt-4' >
                <p className='h4 text-muted' >Auth Requirements</p>
                {platformData.available_platform_auth_requirement.map((item: AVAILABLE_PLATFORM_AUTH_REQUIREMENT) => {
                return (
                    <div key={item.display_name} className='mt-4'>
                    <AuthRequirenmentInput 
                        authData={item}
                        setFormValues={setFormValues}
                        fieldValue={formValues[item.name] ?? '' } 
                    />
                    </div>
                )
                })}
            </div>
            ) : null}
        </Modal.Body>
        <Modal.Footer className='py-2' >
            <div>
            <button type='submit' disabled={loading} className='btn btn-sm btn-success'>
                { loading ? 'Connecting...' : 'Connect'}
            </button>
            <button type='button' disabled={loading} className='btn btn-sm btn-secondary ms-4' onClick={onClose}>
                Cancel
            </button>
            </div>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default ConnectPlatformModal
