import {createAsyncThunk} from '@reduxjs/toolkit'
import AxiosClient from '../AxiosClient'

export const getCatalogDataAsync = createAsyncThunk(
  'auth/getCatalogDataAsync',
  async function (params: any, toolkit) {
    return await AxiosClient('GET', '/api/platform_catalog', [], toolkit, params)
  }
)


export const setSeasonDataAndLeadTimeOfProductAsync = createAsyncThunk(
  'auth/setSeasonDataAndLeadTimeOfProductAsync',
  async function (payload: { rowId:any,  data: any}, toolkit) {
    return await AxiosClient('PUT', `/api/product_settings/${payload.rowId}`, payload.data, toolkit, [])
  }
)


export const getAllLocationsAsync = createAsyncThunk(
  'auth/getAllLocationsAsync',
  async function (_, toolkit) {
    return await AxiosClient('GET', '/api/object_data ', [], toolkit, [])
  }
)
