import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {useAppDispatch} from '../../../../redux/storeHooks'
import {
  requestForResetPassViaMainAsync,
  resetPasswordAsync,
  verifyResetPasswordTokenAsync,
} from '../../../../redux/actions/authActions'
import useAppToast from '../../../../hooks/useAppToast'
import Loader from '../../../../utils/Loader'

const initialValues = {
  password: '',
  confirmPassword: '',
}

const forgotPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Minimum 6 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .min(6, 'Minimum 6 symbols')
    .max(50, 'Maximum 50 symbols')
    .oneOf([Yup.ref('password')], 'Password and Confirm password field must be same')
    .required('Confirm Password is required'),
})

export function ConfirmEmailAndResetPassword() {
  const [loading, setLoading] = useState(false)
  const [verifiedData, setVerifiedData] = useState<null|number>(null)
  const toast = useAppToast()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      if(verifiedData){
        dispatch(resetPasswordAsync({userId:verifiedData+'',payload:{new_password:values.password,confirm_password:values.confirmPassword}}))
        .then(res=>{
          console.log('res of reset', res.payload );
          if(res.payload?.status==200){
            toast.successToast(res.payload?.message)
            resetForm()
            navigate("/auth")
          }

          if(res.payload?.status==404){
            toast.warningToast(res.payload?.message)
            navigate('/auth')
          }

          if(res.payload?.status==500){
            toast.errorToast(res.payload?.message)
            navigate('/auth')

          }

          setLoading(false)
          
        })
      }else{
        toast.warningToast("Id not available")
      }
    },
  })

  useEffect(() => {
    if (params?.encodedUser && params?.resetToken) {
      setLoading(true)
      dispatch(verifyResetPasswordTokenAsync({token: `${params.encodedUser}/${params.resetToken}`}))
      .then((res)=>{
        console.log('res on verify', res)
        if(res.payload?.status==200){
          toast.successToast(res.payload?.message)
          setVerifiedData(res.payload?.user_id)
        }
        
        if(res.payload?.status==400){
          toast.warningToast(res.payload?.data?.message)
          navigate('/auth')
        }

        if(res.payload?.status==500){
          toast.errorToast(res.payload?.message)
        }

        setLoading(false)

      })
      .catch((error)=>{
        console.log('error', error)
        toast.errorToast(error+'')
        setLoading(false)
      })
    }
  }, [])

  if(loading) return <Loader/>

  return (
    <form
      className='form w-lg-25 w-sm-50 w-100 mx-auto my-auto fv-plugins-bootstrap5 fv-plugins-framework  p-10'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>Reset Your Password</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-500 fw-semibold fs-6'>
            Please enter a strong password for more secure
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Sent password reset. Please check your email</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Password</label>
          <input
            type='password'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.password && formik.errors.password},
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm Password</label>
          <input
            type='password'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('confirmPassword')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword},
              {
                'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
              }
            )}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.confirmPassword}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
            <span className='indicator-label'>Set new Password</span>
            {loading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-light'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
    </form>
  )
}
