import { createAsyncThunk } from "@reduxjs/toolkit"
import AxiosClient from "../AxiosClient"



export const getAllSalesAsync = createAsyncThunk(
    'auth/getAllSalesAsync',
    async function(params:any, toolkit) {
      return await AxiosClient('GET', '/api/get_products_bySales', [], toolkit, params)
    }
  )