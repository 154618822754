import React, {FC, useEffect, useState} from 'react'
import AppSwitchInput from '../../components/AppSwitchInput'
import {useAppDispatch} from '../../../redux/storeHooks'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {
  getCountryListAsync,
  getTimezoneListsAsync,
  getUserGeneralSettingsAsync,
  setUserGeneralSettingsAsync,
  updateUserGeneralSettingsAsync,
} from '../../../redux/actions/authActions'
import useAppToast from '../../../hooks/useAppToast'
import Loader from '../../../utils/Loader'
import CustomInfoToolTip from '../../components/CustomInfoToolTip'
import { Country, Timezone } from '../../../_interfaces/auth.interface'

const GeneralSettings: FC = () => {
  const dispatch = useAppDispatch()
  const {successToast, errorToast, warningToast} = useAppToast()
  const [loading, setLoading] = useState(false)
  const [countries, setCountries] = useState<Country[]>([])
  const [timezones, setTimezones] = useState<Timezone[]>([])
  const validationSchema = Yup.object().shape({
    // id: Yup.number().nullable(),
    notify_email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols'),
      // .required('Email is required'),
    lead_time: Yup.number().default(0),
    product_qty_thershlod: Yup.number().required('Threshold value is required'),
    notify_enable: Yup.boolean(),
  })

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    setFieldValue,
    handleSubmit,
    handleBlur,
    setFormikState,
  } = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      id: null,
      notify_email: '',
      lead_time: 0,
      product_qty_thershlod: 0,
      notify_enable: false,
      company_name:'',
      date_format:'',
      country:'',
      region_timezone:'',
    },
    onSubmit: (values, helpers) => {
      console.log(values);
      
      helpers.setSubmitting(true)
      if (values.id) {
        //update api call
        dispatch(updateUserGeneralSettingsAsync({...values}))
          .then((res) => {
            console.log('update setting res', res.payload)
            if (res.payload?.status === 201) {
              successToast('Setting Updated Successfully')
            }
            helpers.setSubmitting(false)
          })
          .catch(() => {
            helpers.setSubmitting(false)
          })
      } else {
        //save api call
        let shadow :any = {...values}
        delete shadow['id']
        dispatch(
          setUserGeneralSettingsAsync({...shadow})
        )
          .then((res) => {
            console.log('save setting res', res.payload)
            if (res.payload?.status === 201) {
              successToast('Settings Saved Successfully')
            }
            helpers.setSubmitting(false)
          })
          .catch(() => {
            helpers.setSubmitting(false)
          })
      }
    },
  })

  async function loadInitialData(){
    setLoading(true)
    const timezones = await  dispatch(getTimezoneListsAsync())
    const countries = await dispatch(getCountryListAsync())
    console.log('inital data for user settings', timezones.payload, countries.payload);

    if(countries.payload?.data?.length){
      setCountries(countries.payload?.data)
    }

    if(timezones.payload?.data?.length){
      setTimezones(timezones.payload?.data)
    }
    
    dispatch(getUserGeneralSettingsAsync([]))
      .then((res) => {
        console.log('gen settings', res.payload)
        if (res.payload?.status === 200) {
          setFormikState((pre)=>{
            return {...pre, values:{...res.payload.data} }
          })
          // setFieldValue('id', res.payload?.data?.id)
          // setFieldValue('notify_email', res.payload?.data?.notify_email)
          // setFieldValue('lead_time', res.payload?.data?.lead_time)
          // setFieldValue('product_qty_thershlod', res.payload?.data?.product_qty_thershlod)
          // setFieldValue('notify_enable', res.payload?.data?.notify_enable)
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    loadInitialData()
  }, [])

  if (loading) return <Loader />

  return (
    <div>
      <div className='row justify-content-center'>
        <div className='col-lg-6 col-md-10 col-sm-8 col-12 '>
          <form onSubmit={handleSubmit}>
            <div className=''>
              <div className='card mt-8' >
              <div className="card-header ribbon ribbon-start top-0 " style={{minHeight:0}} >
                <div className="ribbon-label bg-info">General Settings</div>
              </div>
              <div className="card-body">
                <div className='d-flex align-items-center p-5'>
                  <div className='col-5'>
                    <label className='fw-bold d-flex align-items-center' htmlFor=''>
                      Default Lead time &nbsp; <CustomInfoToolTip msg='Add Default lead time' />
                    </label>
                  </div>
                  <div className='col-7'>
                    <input
                      type='number'
                      className='form-control form-control-sm'
                      placeholder='Lead Time Value'
                      name='lead_time'
                      value={values.lead_time}
                      onChange={(ev) => {
                        if (isNaN(Number(ev.target.value)) || Number(ev.target.value) < 0) {
                          return
                        } else {
                          handleChange(ev)
                        }
                      }}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                {touched.lead_time && errors.lead_time && (
                  <div className='text-danger'>
                    <span role='alert'>{errors.lead_time}</span>
                  </div>
                )}
                <div className='d-flex align-items-center p-5'>
                  <div className='col-5'>
                    <label className='fw-bold d-flex align-items-center' htmlFor=''>
                      Company &nbsp; <CustomInfoToolTip msg='Add Company Name' />
                    </label>
                  </div>
                  <div className='col-7'>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      placeholder='Company Name'
                      name='company_name'
                      value={values.company_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>

                <div className='d-flex align-items-center p-5'>
                  <div className='col-5'>
                    <label className='fw-bold d-flex align-items-center' htmlFor=''>
                      Country &nbsp; <CustomInfoToolTip msg='Add Country' />
                    </label>
                  </div>
                  <div className='col-7'>
                    <select name='country' value={values.country} onChange={handleChange} onBlur={handleBlur} className='form-select form-select-sm'>
                      <option value=''>Select Country</option>
                      {
                        countries.length ? 
                        countries.map((ctry)=>{
                          return(
                            <option value={ctry.id}> {ctry.phonecode} {ctry.name}</option>
                          )
                        }) : null
                      }
                    </select>
                  </div>
                </div>

                <div className='d-flex align-items-center p-5'>
                  <div className='col-5'>
                    <label className='fw-bold d-flex align-items-center' htmlFor=''>
                      Timezone &nbsp; <CustomInfoToolTip msg='Add Timezone' />
                    </label>
                  </div>
                  <div className='col-7'>
                    <select name='region_timezone' value={values.region_timezone} onChange={handleChange} onBlur={handleBlur} className='form-select form-select-sm'>
                      <option value=''>Select Timezone</option>
                      {
                        timezones.length ?
                        timezones.map((tz)=>{
                          return (
                            <option value={tz.id}>{tz.country_name + "/" + tz.time_zone  + ' ' + tz.gmt_offset } </option>
                          )
                        }) 
                        : null
                      }
                    </select>
                  </div>
                </div>

                <div className='d-flex align-items-center p-5'>
                  <div className='col-5'>
                    <label className='fw-bold d-flex align-items-center' htmlFor=''>
                      Date Format &nbsp; <CustomInfoToolTip msg='Add Date Format' />
                    </label>
                  </div>
                  <div className='col-7'>
                    <select name='date_format' value={values.date_format} onChange={handleChange} onBlur={handleBlur} className='form-select form-select-sm'>
                      <option value=''>Select Date Format</option>
                      <option value="DD-MM-YYYY">DD-MM-YYYY</option>
                      <option value="MM-DD-YYYY">MM-DD-YYYY</option>
                      <option value="YYYY-MM-DD">YYYY-MM-DD</option>
                      <option value="DD/MM/YYYY">DD/MM/YYYY</option>
                      <option value="MM/DD/YYYY">MM/DD/YYYY</option>
                      <option value="YYYY/MM/DD">YYYY/MM/DD</option>

                    </select>
                  </div>
                </div>

                <div className='d-flex align-items-center p-5 pb-0'>
                  <div className='col-5'>
                    <label className='fw-bold required d-flex align-items-center' htmlFor=''>
                      Set Threshold Value &nbsp;{' '}
                      <CustomInfoToolTip msg='Set the Product Quantity threshold value after which you want to trigger email.' />
                    </label>
                  </div>
                  <div className='col-7'>
                    <input
                      type='number'
                      className='form-control form-control-sm'
                      placeholder='Threshold Value'
                      name='product_qty_thershlod'
                      onBlur={handleBlur}
                      value={values.product_qty_thershlod}
                      onChange={(ev) => {
                        if (isNaN(Number(ev.target.value)) || Number(ev.target.value) < 0) {
                          return
                        } else {
                          handleChange(ev)
                        }
                      }}
                    />
                  </div>
                </div>
                {touched.product_qty_thershlod && errors.product_qty_thershlod && (
                  <div className='text-danger p-5 pt-0'>
                    <small role='alert'>{errors.product_qty_thershlod}</small>
                  </div>
                )}
              </div>
              </div>

              <div className='card bg-transparent rounded border border-2 mt-10' >
              <div className="card-header ribbon ribbon-start top-0 " style={{minHeight:0}} >
                <div className="ribbon-label bg-info">Notification Settings</div>
              </div>
              <div className='card-body' >
                <div className='pt-5'>
                  <AppSwitchInput
                    key={'notify mail'}
                    isChecked={values.notify_enable}
                    label='Notify updates using email'
                    onChange={handleChange}
                    otherProps={{
                      name: 'notify_enable',
                      onBlur: handleBlur,
                    }}
                  />
                  {touched.notify_enable && errors.notify_enable && (
                    <div className='text-danger'>
                      <span role='alert'>{errors.notify_enable}</span>
                    </div>
                  )}
                </div>
                <div className='d-flex align-items-center pt-5 pb-0'>
                  <div className='col-5' >
                    <label className='fw-bold d-flex align-items-center' htmlFor=''>
                      Email &nbsp; <CustomInfoToolTip msg='Add emails list to get notify' />
                    </label>
                  </div>
                  <div className='col-7' >
                    <textarea
                      required={values.notify_enable}
                      
                      className='form-control form-control-sm mt-2'
                      placeholder='Enter email for notification'
                      name='notify_email'
                      value={values.notify_email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {touched.notify_email && errors.notify_email && (
                    <div className='text-danger'>
                      <small role='alert'>{errors.notify_email}</small>
                    </div>
                  )}
                </div>
              </div>
              </div>
              <div className='p-5 d-flex justify-content-end '>
                <button disabled={isSubmitting} type='submit' className='btn btn-sm btn-info'>
                  {isSubmitting ? 'Saving...' : 'Save Settings'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default GeneralSettings
