import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import {getPlatformSettingsAsync} from '../actions/platformActions'
import {Grouped_Warehouse_Connection, Platform} from '../../_interfaces/platform.interface'
import { getAllLocationsAsync } from '../actions/catalogActions'

const initialState: {
  allWarehouseLoader: boolean
  allWarehouses: Grouped_Warehouse_Connection[]
  allActiveLocations: {
    location_id: number
    name: string
  }[]
} = {
  allWarehouseLoader: false,
  allWarehouses: [],
  allActiveLocations:[]
}

const catalogSlice = createSlice({
  name: 'catalogSlice',
  initialState,
  extraReducers: (builder) => {
    // getLoggedInUserDetailAsync end
    builder.addMatcher(isAnyOf(getPlatformSettingsAsync.pending, getAllLocationsAsync.pending), (state) => {
      console.log('getPlatformSettingsAsync is in pending state ')
      state.allWarehouseLoader = true
    })
    builder.addMatcher(isAnyOf(getPlatformSettingsAsync.rejected, getAllLocationsAsync.rejected), (state) => {
      console.log('getPlatformSettingsAsync is in rejected state ')
      state.allWarehouseLoader = false
    })
    builder.addMatcher(isAnyOf(getPlatformSettingsAsync.fulfilled), (state, action) => {
      if (action.payload?.data) {
        state.allWarehouses = action.payload.data
      }
      state.allWarehouseLoader = false
    })
    builder.addMatcher(isAnyOf(getAllLocationsAsync.fulfilled), (state, action) => {
      if (action.payload?.data) {
        state.allActiveLocations = action.payload.data
      }
      state.allWarehouseLoader = false
    })
    // getLoggedInUserDetailAsync end
  },
  reducers: {},
})

// export const {} = integrationSlice.actions

export default catalogSlice.reducer
