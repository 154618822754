import React, {useEffect, useState} from 'react'
import {Platform_Product} from '../../../_interfaces/platform.interface'
import DataTable, {TableColumn} from 'react-data-table-component'
import Select from 'react-select'
import BreadCrumbs from '../../../utils/BreadCrumbs'
import {useAppDispatch} from '../../../redux/storeHooks'
import {getPlatformProductsAsync} from '../../../redux/actions/platformActions'
import {useParams} from 'react-router-dom'
import Loader from '../../../utils/Loader'

const PlatformProducts = () => {
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  const {platformId} = useParams()
  const [products, setProducts] = useState<Platform_Product[]>([])

  useEffect(() => {
    if (platformId) {
      setLoading(true)
      dispatch(getPlatformProductsAsync({activeSettingId: platformId})).then((response) => {
        console.log('response', response.payload)
        setProducts(response.payload?.data)
        setLoading(false)
      })
    }
  }, [platformId])

  const prd: Platform_Product[] = [
    {
      id: 1,
      product_name: 'The Archived Snowboard',
      current_quantity: 0,
      inventory_sku: '',
      product_price: 629.95,
      predicted_amount: 700.0,
      predicted_quantity: 10.0,
      location: 'My Custom Location',
      overstock: 0,
      shortage: 10.0,
    },
    {
      id: 2,
      product_name: 'The Archived Snowboard',
      current_quantity: 0,
      inventory_sku: '',
      product_price: 629.95,
      predicted_amount: 61.0,
      predicted_quantity: 1.0,
      location: 'Shop location',
      overstock: 0,
      shortage: 1.0,
    },
    {
      id: 3,
      product_name: 'The Archived Snowboard',
      current_quantity: 0,
      inventory_sku: '',
      product_price: 629.95,
      predicted_amount: 66.0,
      predicted_quantity: 8.0,
      location: 'Shop location',
      overstock: 0,
      shortage: 8.0,
    },
    {
      id: 4,
      product_name: 'The Archived Snowboard',
      current_quantity: 0,
      inventory_sku: '',
      product_price: 629.95,
      predicted_amount: 100.0,
      predicted_quantity: 5.0,
      location: 'Shop location',
      overstock: 0,
      shortage: 5.0,
    },
  ]

  const columns: TableColumn<Platform_Product>[] = [
    {
      name: 'Product',
      cell(row, rowIndex, column, id) {
        return (
          <div>
            <p className='fw-bold text-primary cursor-pointer'>{row.product_name}</p>
          </div>
        )
      },
    },
    {
      name: 'Quantity #',
      cell(row, rowIndex, column, id) {
        return (
          <div>
            <p>{row.current_quantity}</p>
          </div>
        )
      },
      sortable: true,
    },
    {
      name: 'Predicted Quantity #',
      cell(row, rowIndex, column, id) {
        return (
          <div>
            <p>{row.predicted_quantity}</p>
          </div>
        )
      },
      minWidth: '160px',
    },
    {
      name: 'SKU',
      cell(row, rowIndex, column, id) {
        return (
          <div>
            <p>{row.inventory_sku}</p>
          </div>
        )
      },
      center: true,
    },
    {
      name: 'Product Price #',
      cell(row, rowIndex, column, id) {
        return (
          <div>
            <p>{row.product_price}</p>
          </div>
        )
      },
      minWidth: '140px',
      sortable: true,
    },
    {
      name: 'Predicted Amount #',
      cell(row, rowIndex, column, id) {
        return (
          <div>
            <p>{row.predicted_amount}</p>
          </div>
        )
      },
      minWidth: '160px',
    },
    {
      name: 'Location',
      cell(row, rowIndex, column, id) {
        return (
          <div>
            <p>{row.location ?? ''}</p>
          </div>
        )
      },
    },
    {
      name: 'Overstock #',
      cell(row, rowIndex, column, id) {
        return (
          <div>
            <p>{row.overstock}</p>
          </div>
        )
      },
    },
    {
      name: 'Shortage #',
      cell(row, rowIndex, column, id) {
        return (
          <div>
            <p>{row.shortage}</p>
          </div>
        )
      },
    },
  ]

  return (
    <div>
      <BreadCrumbs
        currentActive='Shopify Product'
        breadCrumbs={[{title: 'Active Integrations', url: '/active-integrations'}]}
      />
      <div className='card mt-4'>
        <div className='card-header'>
          <div className='card-title'>Products</div>
          <div className='card-toolbar'></div>
        </div>
        <div className='card-body'>
          <DataTable
            striped
            responsive
            progressPending={loading}
            progressComponent={<Loader/>}
            columns={columns}
            data={prd}
            pagination
           customStyles={{
            table:{
              style:{
                minHeight:'55vh'
              }
            }
           }}
          />
        </div>
      </div>
    </div>
  )
}

export default PlatformProducts
